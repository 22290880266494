import { Button } from "antd";
import {
  HeaderWrapper,
  UserDetailsWrapper,
} from "../../pages/UserDetail/UserInfoStyled";
import UserData from "./UserData";
import EditUserInfoModal from "../EditUserInfoModal/EditUserInfoModal";
import { useState } from "react";
import { IUserDetails } from "../../interface/UserDetail";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { CONFIRM_MODAL_MESSAGES } from "../../utils/constants/messages";
import { isCurrentUser, isSuperAdminUser } from "../../utils/utils";

const UserInfoSection = ({
  type = "User",
  data,
  onDelete,
}: {
  type?: string;
  data: IUserDetails;
  onDelete?: Function;
}) => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isSuperAdmin = isSuperAdminUser();

  return (
    <>
      <HeaderWrapper>
        <p className="user-info-header">
          <span className="title">{type} Info</span>
          <span className="sub">
            View/Edit {type.toLowerCase()} information
          </span>
        </p>
        {isSuperAdmin && (
          <Button
            danger
            className={isCurrentUser(data.id) ? "del-btn disabled" : "del-btn"}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete {type.toLowerCase()}
          </Button>
        )}
      </HeaderWrapper>
      <UserDetailsWrapper>
        <UserData type={type} data={data} />
        {isSuperAdmin && (
          <Button
            className={
              isCurrentUser(data.id) ? "edit-btn disabled" : "edit-btn"
            }
            size="large"
            onClick={() => setShowEditUserModal(true)}
          >
            Edit Details
          </Button>
        )}
      </UserDetailsWrapper>
      <EditUserInfoModal
        isOpen={showEditUserModal}
        closeModal={() => setShowEditUserModal(false)}
        type={type}
        data={data}
      />
      <ConfirmationModal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        okText="Delete"
        onOk={() => onDelete && onDelete()}
        title={
          type === "User"
            ? CONFIRM_MODAL_MESSAGES.DELETE_USER.title
            : CONFIRM_MODAL_MESSAGES.DELETE_CUSTOMER.title
        }
        subText={
          type === "User"
            ? CONFIRM_MODAL_MESSAGES.DELETE_USER.description
            : CONFIRM_MODAL_MESSAGES.DELETE_CUSTOMER.description
        }
        customClass="error-btn"
      />
    </>
  );
};

export default UserInfoSection;
