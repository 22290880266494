import { Col, Table, TableProps } from "antd";
import EmptyData from "../../../components/EmptyData/EmptyData";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { ContentWrapper } from "../CustomerDetailsStyled";
import { LoaderLineComponent } from "../../../components/Loader/Loader";
import { ticketsTableColumns } from "./TicketsColumnData";
import { useEffect } from "react";
import { GET } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import {
  reloadTable,
  updateTicketsTableParam,
} from "../../../store/features/Tickets.slice";
import { ITickets } from "../../../interface/CustomerDetails";
import TablePagination from "../../../components/TablePagination/TablePagination";
import { Wrapper } from "../../../utils/TableStyle";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const Tickets = () => {
  const dispatch = useAppDispatch();
  const loadIcon = <LoaderLineComponent />;
  const {
    ticket_list,
    tickets_loader,
    reload_table,
    ticketTableParams,
    total_data,
  } = useAppSelector((state) => state.TicketsReducer);
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const customer = JSON.parse(`${getItem("currentCustomer")}`);
    const url = `${ApiUrl.customerList}${customer.id}/${ApiUrl.tickets}?page_size=10&page=${ticketTableParams.page}`;
    dispatch(GET("tickets/list", url)());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_table]);

  const changePage = (page: number) => {
    dispatch(updateTicketsTableParam({ ...ticketTableParams, page }));
    dispatch(reloadTable(true));
  };

  const changeTableProps: TableProps<ITickets>["onChange"] = (
    _,
    __,
    sorter: any,
    extra
  ) => {
    switch (extra.action) {
      case "sort":
        let ordering = "";
        if (sorter.order === "descend" || sorter.order === "ascend") {
          ordering = `&ordering=${
            sorter.order === "descend"
              ? "-status"
              : sorter.order === "ascend"
              ? "status"
              : ""
          }`;
        }
        dispatch(
          updateTicketsTableParam({
            ...ticketTableParams,
            ordering,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {ticket_list?.length > 0 ? (
        <Wrapper>
          <Col xs={24}>
            <Table
              size="middle"
              pagination={false}
              rowKey="id"
              onChange={changeTableProps}
              scroll={{ x: true }}
              loading={{ indicator: loadIcon, spinning: tickets_loader }}
              dataSource={ticket_list}
              columns={ticketsTableColumns}
            />
          </Col>
          <div className="pagination d-flex">
            <span className="item-count">
              Showing {ticket_list?.length} item(s) out of&nbsp;
              {total_data} results found.
            </span>
            <TablePagination
              total={total_data}
              onChange={changePage}
              current={ticketTableParams?.page}
            />
          </div>
        </Wrapper>
      ) : (
        !tickets_loader && (
          <ContentWrapper>
            <EmptyData type="ticket" hideMessage={true} />
          </ContentWrapper>
        )
      )}
    </>
  );
};

export default Tickets;
