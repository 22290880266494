import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICommonReducer } from "../../interface/Common";
import { STATUS } from "../../utils/constants/common";

export type NotificationType =
  | "success"
  | "info"
  | "warning"
  | "error"
  | undefined;

const initialState: ICommonReducer = {
  loader: false,
  header: {
    title: "",
    subText: "",
    path: "",
  },
  sidebar_active: "customers",
  showToast: false,
  toastMessage: "",
  toastType: STATUS.SUCCESS,
  toastDescription: "",
  toastCategory: "",
  isToastClickable: false,
  page_loader: false,
};
const CommonSlice = createSlice({
  name: "common_reducer",
  initialState,
  reducers: {
    updateSidebarActive: (state, action: PayloadAction<string>) => {
      state.sidebar_active = action.payload;
    },
    updateToggleLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    updatePageLoader: (state, action: PayloadAction<boolean>) => {
      state.page_loader = action.payload;
    },
    updateHeader: (
      state,
      action: PayloadAction<{ title: string; subText: string; path: string }>
    ) => {
      state.header = { ...action.payload };
    },
    setToastMessage: (
      state,
      action: PayloadAction<{
        showToast: boolean;
        toastMessage: string;
        toastType: NotificationType;
        toastDescription?: string;
        category?: string;
        clickable?: boolean;
      }>
    ) => {
      state.showToast = action.payload.showToast;
      state.toastMessage = action.payload.toastMessage;
      state.toastType = action.payload.toastType;
      state.toastDescription = action.payload?.toastDescription || "";
      state.toastCategory = action.payload?.category || "";
      state.isToastClickable = action?.payload?.clickable || false;
    },
    resetCommonReducer: () => initialState,
  },
  extraReducers: () => {},
});
export default CommonSlice.reducer;
export const {
  updateToggleLoader,
  updateHeader,
  updateSidebarActive,
  setToastMessage,
  updatePageLoader,
  resetCommonReducer,
} = CommonSlice.actions;
