import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { ICustomer } from "../../interface/Customer";
import { GET, POST, PUT } from "../../service/api";
import { USER_LIST_TYPE } from "../../pages/CustomerDetails/CustomerDetailsData";
import { STORAGE_KEYS } from "../../utils/constants/common";
import { ICustomerNode, IFile } from "../../interface/ProductDetails";

const initialState = {
  customerDetails: {
    id: "",
    name: "",
    status: "",
    added_date: "",
    role: "",
  },
  pragmaticUsers: {
    type: USER_LIST_TYPE.PRAGMATIC_USERS,
    title: "Assigned Pragmatic Users",
    subtitle: "Assign users to manage and review the customer.",
    buttonLabel: "Assign Pragmatic User",
    adminHasPermission: false,
    data: [],
  },
  customers: {
    type: USER_LIST_TYPE.CUSTOMERS,
    title: "Customer Users",
    subtitle: "Max. 5 users are allowed to view product/platform files.",
    buttonLabel: "Add User",
    adminHasPermission: true,
    data: [],
  },
  total_pages: 0,
  total_data: 0,
  userTableParam: { page: 1, ordering: "" },
  reload_table: false,
  reload_customer_table: false,
  user_table_loader: false,
  openPragmaticUserModal: false,
  openAssignProductModal: false,
  customer_specific_nodes: [] as ICustomerNode[],
  customer_specific_files: [] as IFile[],
  total_pragma_users: [] as { id: string; name: string; email: string }[],
  total_docs: 0,
  total_docs_page: 0,
  doc_current_page: 1,
  reload_doc: false,
  openUnassignUserActionModal: false,
  selectedRecord: {
    id: "",
    name: "",
    type: "",
    assignment_id: "",
    sub_nodes: [],
  },
  reloadNodes: false,
};

const CustomerDetailsSlice = createSlice({
  name: "customer_details_reducer",
  initialState: initialState,
  reducers: {
    setCustomerDetails: (state, action: PayloadAction<ICustomer>) => {
      state.customerDetails = action.payload;
    },
    updateUserTableParam: (
      state,
      action: PayloadAction<{ page: number; ordering: string }>
    ) => {
      state.userTableParam = action.payload;
    },
    updateCustomerDocPage: (state, action: PayloadAction<number>) => {
      state.doc_current_page = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    reloadCustomerTable: (state, action: PayloadAction<boolean>) => {
      state.reload_customer_table = action.payload;
    },
    reloadCustomerDocTable: (state, action: PayloadAction<boolean>) => {
      state.reload_doc = action.payload;
    },
    setUserTableLoader: (state, action: PayloadAction<boolean>) => {
      state.user_table_loader = action.payload;
    },
    setUnassignUserActionModalSt: (state, action: PayloadAction<boolean>) => {
      state.openUnassignUserActionModal = action.payload;
    },
    setAssignPragmaticUserModalOpenSt: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openPragmaticUserModal = action.payload;
    },
    setAssignProductsModalSt: (state, action: PayloadAction<boolean>) => {
      state.openAssignProductModal = action.payload;
    },
    setCustomerSpecificFiles: (
      state,
      action: PayloadAction<ICustomerNode[]>
    ) => {
      state.customer_specific_nodes = action.payload;
    },
    setSelectedRecord: (state, action: PayloadAction<any>) => {
      state.selectedRecord = action.payload;
    },
    reloadCustomerNodes: (state, action: PayloadAction<boolean>) => {
      state.reloadNodes = action.payload;
    },
    resetCustomerDetailReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("customerDetails/assigned_users", "").fulfilled,
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        state.pragmaticUsers.data = results;
        state.total_pages = total_pages;
        state.total_data = total_count;
      }
    );
    builder.addCase(
      GET("customerDetails/assigned_customer_users", "").fulfilled,
      (state, action) => {
        state.customers.data = action?.payload?.data?.results;
      }
    );
    builder.addCase(
      GET("customerDetails/all_users", "").fulfilled,
      (state, action) => {
        state.total_pragma_users = action?.payload?.data?.length
          ? action?.payload?.data
          : [];
      }
    );
    builder.addCase(
      POST("assigned_users/assign_pragmatic_user", "").fulfilled,
      (state, action) => {
        state.user_table_loader = false;
        state.reload_table = false;
      }
    );
    builder.addCase(
      POST("assigned_users/add_customer_user", "").fulfilled,
      (state, action) => {
        state.user_table_loader = false;
        state.reload_customer_table = false;
      }
    );
    builder.addCase(
      GET("customerSpecific/nodes", "").fulfilled,
      (state, action) => {
        state.customer_specific_nodes = action.payload.data?.nodes;
        state.reloadNodes = false;
      }
    );
    builder.addCase(
      GET("customerSpecific/files", "").fulfilled,
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        state.customer_specific_files = results;
        state.total_docs = total_count;
        state.total_docs_page = total_pages;
        state.reload_doc = false;
      }
    );
    builder.addCase(GET("customerSpecific/nodes", "").rejected, (state) => {
      state.customer_specific_nodes = [];
    });
    builder.addCase(GET("customerSpecific/files", "").rejected, (state) => {
      state.total_docs = 0;
      state.total_docs_page = 0;
      state.reload_doc = false;
    });
    builder.addCase(GET("customer/detail", "").fulfilled, (state, action) => {
      state.customerDetails = action.payload.data;
    });
    builder.addCase(PUT("customer/update", "").fulfilled, (state, action) => {
      state.customerDetails = action?.payload?.data;
      localStorage.setItem(
        STORAGE_KEYS.currCustomer,
        JSON.stringify(action?.payload?.data)
      );
    });
    builder.addMatcher(
      isAnyOf(
        GET("customerDetails/assigned_users", "").fulfilled,
        GET("customerDetails/assigned_customer_users", "").fulfilled
      ),
      (state, action) => {
        state.user_table_loader = false;
        if (action.type === "customerDetails/assigned_users/fulfilled")
          state.reload_table = false;
        if (action.type === "customerDetails/assigned_customer_users/fulfilled")
          state.reload_customer_table = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("customerDetails/assigned_users", "").pending,
        GET("customerDetails/assigned_customer_users", "").pending
      ),
      (state, action) => {
        state.user_table_loader = true;
        if (action.type === "customerDetails/assigned_users/pending")
          state.reload_table = false;
        if (action.type === "customerDetails/assigned_customer_users/pending")
          state.reload_customer_table = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("customerDetails/assigned_users", "").rejected,
        GET("customerDetails/assigned_customer_users", "").rejected
      ),
      (state, action) => {
        if (action?.type === "customerDetails/assigned_users/rejected")
          state.pragmaticUsers.data = [];
        if (action?.type === "customerDetails/assigned_customer_users/rejected")
          state.customers.data = [];
        state.total_data = 0;
        state.total_pages = 0;
        state.user_table_loader = false;
      }
    );
  },
});

export default CustomerDetailsSlice.reducer;
export const {
  setCustomerDetails,
  updateUserTableParam,
  updateCustomerDocPage,
  reloadTable,
  setUnassignUserActionModalSt,
  reloadCustomerTable,
  reloadCustomerDocTable,
  setAssignPragmaticUserModalOpenSt,
  setAssignProductsModalSt,
  setCustomerSpecificFiles,
  setSelectedRecord,
  reloadCustomerNodes,
  resetCustomerDetailReducer,
} = CustomerDetailsSlice.actions;
