import { useEffect } from "react";
import EmptyData from "../../../components/EmptyData/EmptyData";
import { DetailsWrapper } from "../../ProductDetail/ProductDetailStyled";
import { CustomerSpecificWrapper } from "../CustomerDetailsStyled";
import CustomerFolderTree from "./CustomerFolderTree/CustomerFolderTree";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCustomerActiveNode } from "../../../store/features/FolderTree";
import CustomerSpecificFiles from "./CustomerSpecificFiles/CustomerSpecificFiles";
import NoFiles from "../../../components/NoFiles/NoFiles";
import { GET } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { useParams } from "react-router-dom";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import { updatePageLoader } from "../../../store/features/Common.slice";
import { isRestrictedFolder } from "../../../utils/utils";

const EMPTY_MSG: {
  [key: string]: string;
} = {
  documentations: "documentation file",
  designs: "design file",
};

const CustomerSpecific = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    customer_specific_nodes,
    customer_specific_files,
    doc_current_page,
    reload_doc,
    reloadNodes,
  } = useAppSelector((state) => state.CustomerDetailsReducer);
  const { page_loader } = useAppSelector((state) => state.CommonReducer);
  const { customer_active_node, document_loader } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const isFolderRestricted = isRestrictedFolder(customer_active_node?.name);
  const emptyDataMsg = EMPTY_MSG[customer_active_node?.name?.toLowerCase()];

  useEffect(() => {
    dispatch(setCustomerActiveNode(customer_specific_nodes[0]));
    customer_specific_nodes?.length &&
      dispatch(
        GET(
          "customerSpecific/files",
          `${ApiUrl.customerSpecificNodes}${customer_specific_nodes[0]?.id}/documents/?page_size=10&page=1`
        )()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_specific_nodes[0]?.id]);

  useEffect(() => {
    _fetchNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reloadNodes && !customer_active_node?.no_of_documents) {
      _fetchNodes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadNodes]);

  useEffect(() => {
    customer_active_node?.id &&
      reload_doc &&
      dispatch(
        GET(
          "customerSpecific/files",
          `${ApiUrl.customerSpecificNodes}${customer_active_node?.id}/documents/?page_size=10&page=${doc_current_page}`
        )()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_doc]);

  const _fetchNodes = () => {
    dispatch(updatePageLoader(true));
    dispatch(
      GET(
        "customerSpecific/nodes",
        `${ApiUrl.customerSpecificNodes}${id}/nodes/`
      )()
    ).then(() => {
      dispatch(updatePageLoader(false));
    });
  };

  return (
    <CustomerSpecificWrapper>
      {customer_specific_nodes?.length ? (
        <DetailsWrapper>
          <div className="tree-section">
            <CustomerFolderTree />
          </div>
          <div className="file-section">
            {customer_specific_files?.length ? (
              <CustomerSpecificFiles />
            ) : !isFolderRestricted && !document_loader ? (
              <NoFiles />
            ) : (
              !document_loader && (
                <EmptyData
                  customMessage={`No ${
                    emptyDataMsg || "customer specific file"
                  }s uploaded yet.`}
                  type={emptyDataMsg}
                  hideMessage={true}
                />
              )
            )}
          </div>
        </DetailsWrapper>
      ) : (
        !page_loader && (
          <EmptyData type="customer specific detail" hideMessage={true} />
        )
      )}
      <UploadDocument activeNode={customer_active_node?.id} />
    </CustomerSpecificWrapper>
  );
};

export default CustomerSpecific;
