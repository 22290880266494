import { MenuProps, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import {
  capitalizeFirstLetter,
  downloadDoc,
  isIndividualUser,
  triggerToastMessage,
} from "../../utils/utils";
import { IFile } from "../../interface/ProductDetails";
import { DownloadArrow } from "../Icons/Icons";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { GET } from "../../service/api";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { FILE_EXT, STATUS } from "../../utils/constants/common";
import { updateToggleLoader } from "../../store/features/Common.slice";
import { FILE_STARTED_DOWNLOAD } from "../../utils/constants/messages";
import { StatusColumn } from "../../utils/TableStyle";
import FileActions from "./FileActions/FileActions";

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const fileActions: MenuProps["items"] = [
  {
    key: "delete",
    label: "Delete",
    danger: true,
  },
  {
    key: "rename",
    label: "Rename",
  },
];

export const DownloadFile = ({ id, name }: { id: string; name: string }) => {
  const dispatch = useAppDispatch();
  const individualUser = isIndividualUser();

  const download = (id: string) => {
    const fileExt = name?.split(".").pop();
    dispatch(
      GET("nodes/downloadFile", `${ApiUrl.downloadDocument}${id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        const { detail } = res?.payload?.data;
        if (individualUser && fileExt === FILE_EXT.PDF) {
          _handlePdfDownload();
          return;
        }
        dispatch(updateToggleLoader(true));
        downloadDoc(detail);
      }
      dispatch(updateToggleLoader(false));
    });
  };

  const _handlePdfDownload = () => {
    triggerToastMessage(
      FILE_STARTED_DOWNLOAD.message,
      "info",
      FILE_STARTED_DOWNLOAD.description
    );
  };

  return (
    <span className="cursor-pointer" onClick={() => download(id)}>
      <DownloadArrow />
    </span>
  );
};

export const documentColumns: ColumnsType<IFile> = [
  {
    title: "File Name",
    dataIndex: "name",
    width: "55%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "File Size",
    dataIndex: "formatted_size",
    width: "15%",
  },
  {
    title: "Uploaded Date",
    dataIndex: "uploaded_date",
    width: "15%",
  },
  {
    title: "Actions",
    dataIndex: "",
    width: "15%",
    render: (_, record) => (
      <StatusColumn>
        <ActionButtonWrapper>
          <Tooltip placement="top" title="Download">
            <DownloadFile id={record?.id} name={record?.name} />
          </Tooltip>
        </ActionButtonWrapper>
        <FileActions data={record} items={fileActions} />
      </StatusColumn>
    ),
  },
];
